import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {MarketplaceAuthRequest, MarketplaceAuthResponse} from "../../_model/marketplace-auth-request.model";
import {Observable, take, tap} from "rxjs";
import {LocalStorageService} from "ngx-localstorage";
import {Router} from "@angular/router";
import {LocalStoragePrefix} from "../../../app.config";
const CURRENT_USER_KEY = 'currentUser';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  redirectUrl = '';

  constructor(
      private http: HttpClient,
      private localStorage: LocalStorageService,
      private router: Router
  ) { }

  public login(request: MarketplaceAuthRequest): Observable<MarketplaceAuthResponse> {
    const url = `${environment.apiBaseUrl}/v4/marketplace/auth`;
    return this.http.post<MarketplaceAuthResponse>(url, request)
        .pipe(take(1), tap(response => this.setCurrentUser(response)));
  }

  public loginWithToken(token: string): Observable<MarketplaceAuthResponse> {
    const url = `${environment.apiBaseUrl}/v4/marketplace/auth-token?token=${token}`;
    return this.http.post<MarketplaceAuthResponse>(url, null)
        .pipe(take(1), tap(response => this.setCurrentUser(response)));
  }

  public logout() {
    if (this.getCurrentUser?.userId) {
      this.logoutServiceCall().subscribe({
        next: () => this.logoutCallback()
      });
    } else {
      this.logoutCallback();
    }
  }

  public logoutCallback() {
    this.removeCurrentUser();
    this.router.navigate(['']);
  }

  public logoutServiceCall() {
    const userId = this.getCurrentUser.userId;
    const url = `${environment.apiBaseUrl}/v1/users/${userId}/logout`;
    return this.http.post(url, {}).pipe(take(1));
  }

  private setCurrentUser(res: MarketplaceAuthResponse) {
    this.logoutCallback(); // To clear existing user details.
    this.localStorage.set(CURRENT_USER_KEY, JSON.stringify(res));
  }

  get getCurrentUser(): MarketplaceAuthResponse {
    return JSON.parse(this.localStorage.get(CURRENT_USER_KEY) as string);
  }

  removeCurrentUser() {
    this.localStorage.remove(CURRENT_USER_KEY, LocalStoragePrefix);
    this.localStorage.clear();
  }

}
